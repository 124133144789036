$icomoon-font-family: "trains" !default;
$icomoon-font-path: "fonts" !default;

$al-ico-markdown: "\e9ec";
$al-ico-hor-expand: "\e9ea";
$al-ico-hor-minimize: "\e9eb";
$al-ico-pdf: "\e9e9";
$al-ico-reports: "\e9e8";
$al-ico-gpu: "\e9e7";
$al-ico-project-path: "\e9e6";
$al-ico-tree-view: "\e9e5";
$al-ico-sort-asc: "\e9e3";
$al-ico-sort-desc: "\e9e4";
$al-ico-grid-view: "\e9e0";
$al-ico-connect: "\e9de";
$al-ico-disconnect: "\e9da";
$al-ico-trash-all: "\e9d9";
$al-ico-drag: "\e9d8";
$al-ico-py: "\e9d7";
$al-ico-file: "\e9d3";
$al-ico-txt: "\e9d6";
$al-ico-pkl: "\e9cd";
$al-ico-image: "\e958";
$al-ico-zip: "\e9ce";
$al-ico-code-file: "\e9a4";
$al-ico-audio: "\e9d0";
$al-ico-upload: "\e9cc";
$al-ico-min-panel: "\e9ca";
$al-ico-max-panel: "\e9cb";
$al-ico-datasets: "\e90b";
$al-ico-t-logo-b: "\e908";
$al-ico-bars-menu: "\e933";
$al-ico-home: "\e909";
$al-ico-projects: "\e90a";
$al-ico-queues: "\e90c";
$al-ico-annotator: "\e90d";
$al-ico-account: "\e998";
$al-ico-compare: "\e93f";
$al-ico-archive: "\e92c";
$al-ico-cloud-head: "\e9a6";
$al-ico-how-to: "\e997";
$al-ico-compare-c: "\e93a";
$al-ico-published-title: "\e9d2";
$al-ico-publish: "\e9cf";
$al-ico-unpublish: "\ea0f";
$al-ico-published: "\e9d1";
$al-ico-info: "\e99b";
$al-ico-warn: "\ea10";
$al-ico-settings: "\e9e2";
$al-ico-trash: "\ea0b";
$al-ico-arrow-from-right: "\e92e";
$al-ico-arrow-from-left: "\e92d";
$al-ico-arrow-to-bottom: "\e92f";
$al-ico-arrow-to-top: "\e930";
$al-ico-training: "\e90e";
$al-ico-test: "\ea09";
$al-ico-testing: "\ea0a";
$al-ico-type-training: "\ea33";
$al-ico-type-testing: "\ea32";
$al-ico-type-data-processing: "\ea2c";
$al-ico-type-qc: "\ea30";
$al-ico-type-service: "\ea31";
$al-ico-type-optimizer: "\ea2f";
$al-ico-type-monitor: "\ea2e";
$al-ico-type-inference: "\ea2d";
$al-ico-type-application: "\ea29";
$al-ico-type-controller: "\ea2a";
$al-ico-type-custom: "\ea2b";
$al-ico-how-to1: "\e90f";
$al-ico-model: "\e910";
$al-ico-temp-edit: "\e911";
$al-ico-dialog-x: "\e980";
$al-ico-temp-image: "\e912";
$al-ico-temp-list-alt: "\e913";
$al-ico-dog: "\e914";
$al-ico-alert-purple: "\e927";
$al-ico-back: "\e931";
$al-ico-cat: "\e936";
$al-ico-clone: "\e937";
$al-ico-completed: "\e940";
$al-ico-data-audit: "\e943";
$al-ico-download-frames: "\e982";
$al-ico-dropdown-arrow: "\e984";
$al-ico-ellipse-icon: "\e986";
$al-ico-filter: "\e992";
$al-ico-fit: "\e995";
$al-ico-id: "\e999";
$al-ico-info-max: "\e99c";
$al-ico-info-min: "\e99d";
$al-ico-keypoint: "\e99f";
$al-ico-list-view: "\e9a5";
$al-ico-minus: "\e9a7";
$al-ico-previous: "\e9bb";
$al-ico-next: "\e9af";
$al-ico-plus: "\e9b8";
$al-ico-polygon: "\e9b9";
$al-ico-pytorch-icon: "\e9d4";
$al-ico-question-mark: "\e9d5";
$al-ico-rectangle: "\e9db";
$al-ico-running: "\e9df";
$al-ico-setup: "\ea0d";
$al-ico-undo: "\ea0e";
$al-ico-zoom-1-to-1: "\ea25";
$al-ico-zoom-in: "\ea26";
$al-ico-zoom-out: "\ea27";
$al-ico-search: "\e9e1";
$al-ico-zoom-to-fit: "\ea28";
$al-ico-reset: "\e9dd";
$al-ico-import: "\e99e";
$al-ico-export: "\e9b6";
$al-ico-between: "\e934";
$al-ico-next-batch: "\e9b0";
$al-ico-prev-batch: "\e9ba";
$al-ico-back-to-top: "\e932";
$al-ico-redo: "\e9dc";
$al-ico-download: "\e981";
$al-ico-edit: "\e983";
$al-ico-pending: "\e9b7";
$al-ico-aborted: "\e925";
$al-ico-success: "\e9f5";
$al-ico-alert-outline: "\e926";
$al-ico-github: "\e915";
$al-ico-description: "\e916";
$al-ico-line-expand: "\e900";
$al-ico-user-logout: "\e901";
$al-ico-documentation: "\e919";
$al-ico-frame-rule: "\e91a";
$al-ico-source-rule: "\e91b";
$al-ico-get-link-dialog: "\e91d";
$al-ico-iteration: "\e91e";
$al-ico-mapping: "\e91f";
$al-ico-labels: "\e920";
$al-ico-augmentation: "\e921";
$al-ico-filter-outlined: "\e922";
$al-ico-input-data: "\e923";
$al-ico-help-outlined: "\e924";
$al-ico-email: "\e928";
$al-ico-tips: "\e929";
$al-ico-caret-right: "\e92a";
$al-ico-currently-active: "\e92b";
$al-ico-complete: "\e92b";
$al-ico-access-key: "\e935";
$al-ico-alert: "\e9b3";
$al-ico-users: "\e91c";
$al-ico-exit-archive: "\e938";
$al-ico-calendar: "\e939";
$al-ico-time: "\e93b";
$al-ico-add: "\e93c";
$al-ico-time-colon: "\e93d";
$al-ico-regex: "\e93e";
$al-ico-filter-on-path1: "\e941";
$al-ico-filter-on-path2: "\e942";
$al-ico-filter-off: "\e945";
$al-ico-sort-off: "\e946";
$al-ico-sort-on-down-path1: "\e947";
$al-ico-sort-on-down-path2: "\e948";
$al-ico-sort-on-up-path1: "\e949";
$al-ico-sort-on-up-path2: "\e94a";
$al-ico-arrow-left: "\e94b";
$al-ico-arrow-right: "\e94c";
$al-ico-step-backward: "\e94d";
$al-ico-step-forward: "\e94e";
$al-ico-backward: "\e94f";
$al-ico-forward: "\e950";
$al-ico-copy-to-clipboard: "\e951";
$al-ico-card-example: "\e952";
$al-ico-bold: "\e953";
$al-ico-italic: "\e954";
$al-ico-heading: "\e955";
$al-ico-quote: "\e956";
$al-ico-link: "\e957";
$al-ico-code: "\e959";
$al-ico-list-bulleted: "\e95a";
$al-ico-list-numbered: "\e95b";
$al-ico-me: "\e95c";
$al-ico-team: "\e95d";
$al-ico-task-desc: "\e95e";
$al-ico-dots: "\e95f";
$al-ico-move-to: "\e960";
$al-ico-abort: "\e961";
$al-ico-extend: "\e962";
$al-ico-reset-exp: "\e963";
$al-ico-tag: "\e964";
$al-ico-shared-item: "\e965";
$al-ico-restore: "\e966";
$al-ico-workers: "\e967";
$al-ico-dots-v-menu: "\e96a";
$al-ico-d-menu-down: "\e96b";
$al-ico-d-menu-up: "\e96c";
$al-ico-slash: "\e96d";
$al-ico-info-circle: "\e96e";
$al-ico-annotate: "\e96f";
$al-ico-task-desc-outline: "\e970";
$al-ico-manage-queue: "\e968";
$al-ico-enqueue: "\e969";
$al-ico-dequeue: "\e971";
$al-ico-applications: "\e972";
$al-ico-ico-chevron-up: "\e973";
$al-ico-ico-chevron-down: "\e974";
$al-ico-no-data-graph: "\e975";
$al-ico-no-scatter-graph: "\e976";
$al-ico-applications-exp: "\e944";
$al-ico-auto-refresh-play-path1: "\e977";
$al-ico-auto-refresh-play-path2: "\e978";
$al-ico-auto-refresh-pause-path1: "\e979";
$al-ico-auto-refresh-pause-path2: "\e97a";
$al-ico-sqr-ok: "\e97b";
$al-ico-sqr-cancel: "\e97c";
$al-ico-queue-lg: "\e97d";
$al-ico-started-lg: "\e97e";
$al-ico-admin: "\e97f";
$al-ico-projects-outlined: "\e985";
$al-ico-datasets-outlined: "\e987";
$al-ico-hide: "\e988";
$al-ico-show: "\e989";
$al-ico-metadata: "\e98a";
$al-ico-filter-reset-path1: "\e98b";
$al-ico-filter-reset-path2: "\e98c";
$al-ico-version-label: "\e98d";
$al-ico-plugin: "\e98e";
$al-ico-abort-all: "\e98f";
$al-ico-refresh: "\e990";
$al-ico-rocket: "\e991";
$al-ico-logout: "\e993";
$al-ico-settings-alert-path1: "\e994";
$al-ico-settings-alert-path2: "\e996";
$al-ico-platform: "\e99a";
$al-ico-creditcard: "\e9a0";
$al-ico-star: "\e9a1";
$al-ico-email-check: "\e9a2";
$al-ico-slack: "\e9a3";
$al-ico-youtube: "\e9a8";
$al-ico-lock: "\e9a9";
$al-ico-lock-open: "\e9aa";
$al-ico-no-code: "\e9ab";
$al-ico-calendar-checked: "\e9ad";
$al-ico-no-source: "\e9ae";
$al-ico-arrow-up: "\e9b1";
$al-ico-arrow-down: "\e9b2";
$al-ico-error-circle: "\e9b4";
$al-ico-pipelines: "\e9b5";
$al-ico-console: "\e9bc";
$al-ico-link-arrow: "\e9bf";
$al-ico-broken-file: "\e9c0";
$al-ico-run: "\e9c1";
$al-ico-table-view: "\e9c2";
$al-ico-experiment-view: "\e9c3";
$al-ico-code-square: "\e9c4";
$al-ico-video: "\e9c5";
$al-ico-less-than: "\e9c6";
$al-ico-greater-than: "\e9c7";
$al-ico-eye-outline: "\e9c8";
$al-ico-csv: "\e9c9";
$al-ico-status-draft: "\e902";
$al-ico-status-published: "\e906";
$al-ico-status-aborted-sec: "\e918";
$al-ico-status-pending: "\e903";
$al-ico-status-skipped: "\e9bd";
$al-ico-status-cached: "\e9be";
$al-ico-status-executed: "\e9ac";
$al-ico-status-running: "\e904";
$al-ico-status-failed: "\e907";
$al-ico-status-aborted: "\e917";
$al-ico-status-completed: "\e905";

