@import "variables";

body .mat-form-field-outline-gap {
  display: none;
}

body .mat-slide-toggle-bar {
  width: 30px;
}

body .mat-slide-toggle-thumb-container {
  width: 14px;
  height: 14px;
  top: 0;
  left: 0;
}

body .mat-slide-toggle .mat-slide-toggle-ripple {
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  height: 32px;
  width: 32px;
  border-radius: 32px;
}

body .mat-slide-toggle-thumb {
  height: 14px;
  width: 14px;
}

/* checked */
body .dark-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(198, 255, 0, 0.10);
}


body mat-form-field.mat-form-field-appearance-outline {
  &.readonly:not(.dark) {
    .mat-select-value-text, .mat-input-element {
      color: $blue-400;
    }
  }

  &.dark {
    .mat-select-value-text, .mat-input-element, .mat-select-arrow {
      color: $blue-grey;
    }
  }

  &.append-left.mat-form-field-should-float {
    width: 340px;
    margin-left: 160px;

    .mat-form-field-wrapper {
      margin: 0.25em;

      .mat-form-field-subscript-wrapper {
        z-index: 1;
      }
    }

    .mat-form-field-label-wrapper {
      .mat-form-field-label {
        top: 42px;
        margin-left: -160px;
        width: 156px;
        white-space: normal;
        position: relative;
        padding-right: 20px;

      }

    }
  }

  &.mat-form-field-should-float .mat-form-field-label-wrapper {
    margin-left: -10px;
    overflow: visible;


    .mat-form-field-label {
      overflow: unset;
      width: unset;
      top: 24px;
      margin-bottom: unset;
      transform: translateY(-1.59373em) scale(1);
      font-size: 12px;
    }
  }

  .mat-form-field-prefix, .mat-form-field-suffix {
    top: .5em;
  }

  .mat-form-field-outline-thick {
    background: white;
    color: $blue-250;

    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border-width: 1px;
    }
  }

  .mat-form-field-label-wrapper .mat-form-field-label {
    top: 30px;
    color: $blue-400;
  }

  &.mat-focused:not(.mat-form-field-invalid) .mat-form-field-label {
    color: $blue-400;
  }

  .mat-form-field-subscript-wrapper {
    padding: 0;
  }


  .mat-form-field-outline {
    background: white;
    top: 24px;
    border-radius: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 0.5em 0;

    .mat-form-field-flex {
      margin-top: -28px;
    }
  }

  &.mat-form-field-has-label .mat-form-field-flex {
    margin-top: 0em;
  }

  .mat-form-field-infix {
    top: 7px;
    padding: 1.2em 0;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 22px;
    padding: 0;
  }

}

body .dark.mat-form-field-appearance-outline:not(.data-text) .mat-form-field-outline {
  background-color: #1a1e2c;
}

body .dark-theme {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: #1a1e2c;
  }
}


body .data-text.mat-form-field-appearance-outline .mat-form-field-outline {
  .mat-form-field-outline-end, .mat-form-field-outline-start {
    border: none;
  }
}

body .mat-form-field-infix {
  padding-bottom: 8px;
}

body .mat-form-field-label-wrapper {
  .mat-form-field-label{
    pointer-events: auto;
    .mat-form-field-required-marker{
      color: #f44336;
    }
  }

  .mat-form-field-empty {
    top: 27px;
  }
}

//body .mat-form-field.mat-form-field-should-float .mat-form-field-label-wrapper {
//  margin-left: -10px;
//  top: -7px;
//  overflow: visible;
//
//  .mat-form-field-label {
//    top: 24px;
//    height: 14px;
//    margin-bottom: unset;
//
//  }
//}

body .mat-form-field-subscript-wrapper {
  margin-top: 3px;
}

body .mat-select-arrow-wrapper {
  transform: translateY(0%) !important;
}


body .mat-form-field-infix .mat-input-element {
  height: 16px;
}

body .mat-checkbox-checked.mat-accent {
  .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $purple;
  }

  &.readonly .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $blue-300;
  }
}


body .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $blue-400;
}

body .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: initial;
}

mat-option.mat-option.dark {
  color: white;
}

body .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: white;
}

/// THIN MAT_FORM_FIELD

body mat-form-field.mat-form-field-appearance-outline.dark {

  &.black {
    .mat-form-field-outline {
      background-color: #000 !important;
    }
  }

  .mat-form-field-outline {
    background-color: $blue-800 !important;
    border-radius: 4px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    top: 6px;
    padding: 1.2em 0;
  }

  .mat-form-field-outline-end, .mat-form-field-outline-start {
    border: none;
  }

  mat-select.mat-select-empty .mat-select-value {
    color: rgba(255, 255, 255, 0.5);
  }

  .mat-form-field-label-wrapper .mat-form-field-label {
    color: #b9bdd4;

  }

  .mat-form-field-subscript-wrapper {

    .mat-error.dark {
      color: #ff6f81;
    }
  }
}

body mat-form-field.mat-form-field-appearance-outline.dark-outline {

  .mat-form-field-outline {
    background-color: transparent !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    top: 6px;
    padding: 1.2em 0;
  }

  .mat-form-field-outline-start {
    border: 1px solid $blue-600;
    border-right-style: none;
    transition: border 0.2s;
  }

  .mat-form-field-outline-end {
    border: 1px solid $blue-600;
    border-left-style: none;
    transition: border 0.2s;
  }

  &:hover {
    .mat-form-field-outline-start {
      border: 1px solid $grey-purple;
      border-right-style: none;
    }

    .mat-form-field-outline-end {
      border: 1px solid $grey-purple;
      border-left-style: none;
    }
  }

  .mat-form-field-subscript-wrapper {

    .mat-error.dark {
      color: #ff6f81;
    }
  }
}

body.dark-theme .mat-select-panel.dark-outline {
  background-color: #000;
  border: 1px solid $blue-500;
  padding: 4px;
  max-height: 280px;

  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: $blue-700;
  }

  .mat-option {
    color: $blue-200;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 2px;
    &:last-child {margin-bottom: 0;}

    &.mat-selected:not(.mat-option-multiple), &.mat-active {
      background: $blue-800;
    }
  }

  .mat-pseudo-checkbox {
    color: $blue-200;
  }

  &.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $blue-200;
  }
}


body mat-form-field.mat-form-field-appearance-outline.no-padding {
  .mat-form-field-flex {
    padding: 0;
  }
}


mat-form-field {
  &.no-bottom-border-radius {
    .mat-form-field-outline-start {
      border-radius: 4px 0 0 0;
    }

    .mat-form-field-outline-end {
      border-radius: 0 4px 0 0;
    }
  }

  &.no-right-border-radius {
    .mat-form-field-outline-end {
      border-radius: 0;
    }
  }

  &.no-left-border-radius {
    .mat-form-field-outline-start {
      border-radius: 0;
    }
  }
}

body .mat-autocomplete-panel {
  .mat-option {
    line-height: 36px;
    height: 36px;
    font-size: 14px;
  }
}

body.dark-theme .mat-select-panel.light-theme,
body.dark-theme .mat-autocomplete-panel.light-theme {
  background: #fff;
  padding: 4px;

  .mat-option {
    color: rgba(0, 0, 0, .87);
    border-radius: 4px;
    margin-bottom: 2px;
    &:last-child {margin-bottom: 0;}

    &.mat-selected:not(.mat-option-multiple), &.mat-active {
      background: darken($blue-50, 2%);
    }

    &.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
      color: rgba(0, 0, 0, .87);
    }
  }

  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: $blue-50;
  }

}


// select menu dark
body.dark-theme .mat-select-panel.dark,
body.dark-theme .mat-autocomplete-panel.dark
{
  background: $black;
  border: 1px solid $blue-500;

  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: lighten($blue-800, 5);
  }

  .mat-option {
    color: rgba($white, .87);

    &.mat-active {
      background: lighten($blue-800, 1);
    }

    &.mat-selected:not(.mat-option-multiple) {
      background: lighten($blue-800, 8);

      &:not(.mat-active):not(:hover):not(.mat-option-disabled) {
        color: rgba($white, .87);
      }
    }
  }

  .mat-pseudo-checkbox {
    color: rgba($white, .87);
  }

  &.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: rgba($white, .87);
  }

  &.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $purple;
  }
}

body .mat-autocomplete-panel.annotator-autocomplete {
  border: 1px solid $grey-purple;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 4px;
  background: #000000;
}

.dark-theme .mat-slide-toggle-thumb {
  background-color: $blue-400;
}

.mat-slider-track-background {
  background-color: $cloudy-blue-two;
}

.dark-theme .mat-slider {
  &.mat-slider-disabled {
    opacity: 0.5;
  }

  .mat-slider-track-background {
    background-color: rgba(167, 178, 216, 0.3);
  }

  .mat-slider-track-fill {
    background-color: $blue-280 !important;
  }
}

.mat-slider-track-fill {
  background-color: $blue-500 !important;
}

.mat-slider-thumb {
  background-color: $blue-250 !important;

  &:hover {
    background-color: $blue-250 !important;
  }
}


// date picker
.mat-datepicker-container {
  .mat-icon-button {
    height: 20px;
    width: 20px;
  }

  .mat-datepicker-toggle {
    color: $blue-300;
  }
}

.mat-menu-panel.dark {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px $blue-600;
  background-color: $black;

  .mat-menu-item {
    color: $blue-200;
    &:disabled{
      opacity: 0.5;
    }
    &:hover {
      background-color: $dark-grey-blue;
    }
  }
  .mat-menu-item-highlighted {background: $blue-800}
  .mat-menu-submenu-icon {color: $blue-300}
  .mat-menu-content hr {margin: 4px -4px;}
}


.light-theme .mat-menu-item:hover:not([disabled]),
.light-theme .mat-menu-item-highlighted:not([disabled]) {
  background: $blue-50;
}
